import axios from 'axios'

export default {
    async getById(id) {
        try {
            const response = await axios.get(`/credentialbank/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/credentialbank', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/credentialbank/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
