<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('bank.bank')"
        prop="bank"
      >
        <el-select v-model="form.bank" placeholder="Select bank" class="w-100">
          <el-option v-for="item in banks" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('bank.link')"
        prop="link"
      >
        <el-input type="text" v-model="form.link" placeholder="Link of web"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('bank.type')"
        prop="type"
      >
        <el-select v-model="form.type" placeholder="Select type" class="w-100">
          <el-option
            v-for="item in bankAccounts"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('bank.user')"
        prop="user"
      >
        <el-input type="text" v-model="form.user" placeholder="User in Bank"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('bank.password')"
        prop="password"
      >
        <el-input type="text" v-model="form.password" placeholder="Password"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('bank.routing')"
        prop="routing"
      >
        <el-input type="text" v-model="form.routing" placeholder="Routing"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('bank.account')"
        prop="account"
      >
        <el-input type="text" v-model="form.account" placeholder="Bank Account"></el-input>
      </el-form-item>

      <el-button type="primary" @click="(!data)?submitForm('form'):updateForm('form')">Save data</el-button>
    </el-form>
  </div>
</template>

<script>
import bank from "@/services/api/bank";
import bankAccount from "@/services/api/bankAccount";
import credentialBank from "@/services/api/credentialBank";

export default {
  props: ["data"],
  data() {
    return {
      banks: [],
      bankAccounts: [],
      form: {
        id: null,
        bank: null,
        link: null,
        type: null,
        user: null,
        password: null,
        routing: null,
        account: null,
        corporate: this.$route.params.id
      }
    };
  },
  mounted() {
    bank.get().then(response => {
      this.banks = response;
    });
    bankAccount.get().then(response => {
      this.bankAccounts = response;
    });
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.bank = this.data.bankId;
      this.form.link = this.data.link;
      this.form.type = this.data.typeId;
      this.form.user = this.data.user;
      this.form.password = this.data.password;
      this.form.routing = this.data.routing;
      this.form.account = this.data.account;
      this.form.corporate = this.data.corporateId;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          credentialBank
            .create(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          credentialBank
            .update(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
